import { render, staticRenderFns } from "./SINPMenu.vue?vue&type=template&id=25f2935c&scoped=true&"
import script from "./SINPMenu.vue?vue&type=script&lang=js&"
export * from "./SINPMenu.vue?vue&type=script&lang=js&"
import style0 from "./SINPMenu.vue?vue&type=style&index=0&id=25f2935c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f2935c",
  null
  
)

export default component.exports